/*
** DO NOT EDIT THIS FILE! All changes will be overwritten.
** edit the master in app/javascript/themes/snowplows/_template_config.js,
** then run script/activate-theme snowplows
*/

//
//    ####   #    #   ####   #    #  #####   #        ####   #    #   ####
//   #       ##   #  #    #  #    #  #    #  #       #    #  #    #  #
//    ####   # #  #  #    #  #    #  #    #  #       #    #  #    #   ####
//        #  #  # #  #    #  # ## #  #####   #       #    #  # ## #       #
//   #    #  #   ##  #    #  ##  ##  #       #       #    #  ##  ##  #    #
//    ####   #    #   ####   #    #  #       ######   ####   #    #   ####
//
// This file is installed into config/javascript/siteConfigVars.js
// run script/activate-theme after making any changes

export const SITE_CONFIG = {
  theme: 'snowplows',
}


/*
** config/javascript/siteConfigVars.js
** installed by ./script/activate-theme snowplows
** at Mon 23 Sep 2024 04:45:30 PM UTC on misterplow02
*/
